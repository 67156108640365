html,
body,
#root {
  height: 100% !important;
  width: 100%;
  min-height: 100vh !important;
  min-width: 100vw;
  padding: 0;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  font-display: swap;
  position: absolute;
}

[data-reactroot] 
        {
          height: 100% !important; 
          position: absolute;     
          width: 100% !important;    
        }

        body > #root > div {
          height: 100% !important;
        }